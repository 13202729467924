<template>
  <div class="about">
    <BannerKv
      :titleEn="BannerKvData.titleEn"
      :titleZh="BannerKvData.titleZh"
      :backgroundPic="BannerKvData.backgroundPic"
      :bgcolor="BannerKvData.bgcolor"/>
    <AboutIntroduction
      :titleEn="AboutIntroductionData.titleEn"
      :titleZh="AboutIntroductionData.titleZh"
      :intro="AboutIntroductionData.intro"
      :items="AboutIntroductionData.items"/>
    <AboutDoctorIntroduction
      :titleEn="AboutDoctorIntroductionData.titleEn"
      :titleZh="AboutDoctorIntroductionData.titleZh"
      :info="AboutDoctorIntroductionData.info"
      :link="AboutDoctorIntroductionData.link"
      :decoPic="AboutDoctorIntroductionData.decoPic"/>
    <AboutDoctors/>
    <AboutMore
      :items="AboutMoreData.items"/>
  </div>
</template>

<script>
import '@/assets/scss/about.scss';
import BannerKv from '@/components/BannerKv.vue';
import AboutIntroduction from '@/components/about/AboutIntroduction.vue';
import AboutDoctorIntroduction from '@/components/about/AboutDoctorIntroduction.vue';
import AboutDoctors from '@/components/about/AboutDoctors.vue';
import AboutMore from '@/components/about/AboutMore.vue';
// eslint-disable-next-line
import { getProcreationArticles, getIvfTeamsContent } from '@/lib/public';
import { mapMutations, mapGetters } from 'vuex';

import bannerKvPic from '@/assets/img/About_NUWA/service_1.jpg';
// import more01 from '@/assets/img/about/more_1.jpg';
import more02 from '@/assets/img/about/more_2_new.jpg';
import more03 from '@/assets/img/about/more_3_new.jpg';
// import banner1 from '@/assets/img/temp/banner_1.jpg';
import ivf from '@/assets/img/temp/ivf.jpg';

export default {
  name: 'aboutIvf',
  components: {
    BannerKv,
    AboutIntroduction,
    AboutDoctorIntroduction,
    AboutDoctors,
    AboutMore,
  },
  data() {
    return {
      BannerKvData: {
        titleEn: 'NUWA FERTILITY',
        titleZh: this.$t('nuwaIvf'),
        backgroundPic: bannerKvPic,
        bgcolor: '#f8f8f8',
      },
      AboutIntroductionData: {
        // titleEn: 'Treatment Introduction',
        titleZh: this.$t('ivfTitle'),
        intro: this.$t('aboutIvfIntro'),
        items: [],
      },
      AboutDoctorIntroductionData: {
        titleEn: 'DOCTOR<br>INTRODUCTION',
        titleZh: this.$t('ivfTeamsTitle'),
        info: this.$t('ivfTeamsIntro'),
        link: '/staff/procreation/p1',
        decoPic: ivf,
      },
      AboutMoreData: {
        items: [
          {
            link: '/about/tcm',
            pic: more02,
            titleEn: 'NUWA TCM CENTER',
            titleZh: this.$t('tcmTitle'),
            info: this.$t('aboutTcmIntro'),
          },
          {
            link: '/about/lab',
            pic: more03,
            titleEn: 'NUWA LAB',
            titleZh: this.$t('nuwaLabs'),
            info: this.$t('aboutLabsIntro'),
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      'getLangUUID',
    ]),
  },
  methods: {
    ...mapMutations([
      'setLangUUID',
    ]),
    getData() {
      this.AboutIntroductionData.items = [];
      getProcreationArticles(
        this.getLangUUID,
        (response) => {
          response.data.data.forEach((elem) => {
            console.log(elem);
            const temp = {
              pic: elem.icon,
              title: elem.title,
              brief_info: elem.brief_info,
              type: 'link',
              parameter: {
                class: 'ivf',
                id: elem.slug,
              },
            };
            this.AboutIntroductionData.items.push(temp);
          });
        },
      );
      getIvfTeamsContent(
        this.getLangUUID,
        (response) => {
          console.log(response);
          console.log(response.data.data);
          this.AboutDoctorIntroductionData = {
            titleEn: 'DOCTOR<br>INTRODUCTION',
            titleZh: response.data.data.title,
            info: response.data.data.content,
            link: '/staff/procreation/p1',
            decoPic: response.data.data.image,
          };
        },
      );
    },
  },
  watch: {
    getLangUUID(val) {
      if (val !== null) {
        this.getData();
      }
    },
  },
  mounted() {
    if (this.getLangUUID !== null) {
      this.getData();
    }
  },
};
</script>
